import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    carmichaelImage: file(relativePath: { eq: "carmichael.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithWithCarImage: file(
      relativePath: { eq: "locksmith-with-car.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithWorkingOnDoorLockImage: file(
      relativePath: { eq: "locksmith-working-on-door-lock.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithCarInNeighborhoodImage: file(
      relativePath: { eq: "locksmith-car-in-neighborhood.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    laserKeyMachineImage: file(relativePath: { eq: "laser-key-machine.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function CarmichaelPage({ data }) {
  return (
    <MainLayout
      title="24 Hr Cheap Locksmith in Carmichael, CA | 916-995-0135"
      description="We specialize with residential, commercial & automotive lock & keys. Competitive prices, fast response & highly trained team. Mobile locksmith services."
      offset
    >
      <ContactHeading background={data.carmichaelImage.childImageSharp.fluid}>
        <h1 className="text-center">Carmichael's Trusted Locksmith</h1>
        <h2 className="text-center font-weight-light ">
          24 Hour Carmichael Locksmith
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section" className="pb-0 pb-md-5">
        <h2 className="text-center">Carmichael's Full Service Locksmith</h2>
        <Row>
          <Col xs={12} md={{ span: 6, order: 2 }}>
            <p className="indent">
              Swift Locksmith is your trusted company, proudly serving
              Carmichael, CA and the surrounding areas. Our trained technicians
              are ready to assist you in any locksmith situation from losing
              your house keys, being locked out of your vehicle, needing lock
              replacements or simply upgrading your home/business security. We
              understand that some of these unforeseen situations can happen at
              any time and can be stressful and that is why we are always
              available, 24 hours a day, seven days a week so you can get back
              to your normal routine.
            </p>
            <p className="indent mb-md-0">
              We want to ensure that all of our customers, existing and new,
              always get treated with the utmost customer service, urgency and
              professionalism. We offer competitive pricing so there are never
              any hidden fees because we are always honest and upfront with you.
            </p>
          </Col>
          <Col xs={12} md={{ span: 6, order: 1 }} className="col-sm-pad">
            <Img
              fluid={data.locksmithWithCarImage.childImageSharp.fluid}
              alt="locksmith-with-car"
            />
          </Col>
        </Row>
      </Container>
      <section className="pt-0 pt-md-5">
        <div className="background-content bg-dark text-white">
          <Container>
            <h2 className="text-center">What We Offer</h2>
            <h4 className="text-primary">Carmichael's Residential Services</h4>
            <p className="indent">
              It's very important to have a sense of security in your own home
              and our goal is to provide you with the best residential locksmith
              solutions. We offer different types of key locks such as dead
              bolts, knob sets and even wireless locks so you can pick what
              works best for you. Our technicians offer different security
              options from basic to high security as well as to accommodate
              different budgets.
            </p>
            <p className="indent">
              If you find yourself locked out of your home, need to repair
              locks, or looking to upgrade your home security and not sure what
              the best options are, call Swift Locksmith and our knowledgeable
              team will be happy to provide options and assistance for you.
            </p>
            <h4 className="text-primary">Carmichael's Commercial Services</h4>
            <p className="indent">
              Whether you own an office, small neighborhood store, a private
              practice or an art studio, you want to make sure your business is
              fully secured at all times and we can help you get premium
              locksmith protection. Our technicians offer a personalized
              experience for each customer based on what they are looking for so
              we always have your needs in mind.
            </p>
            <p className="indent">
              We use the latest technology to offer newer and better features
              for commercial security. From business lockouts,
              changing/repairing locks to electronic pads, we have you covered.
              Our goal is to secure your goods, inventory, customers and staff
              so you have less stress to worry about and more time to focus on
              your business.
            </p>
            <h4 className="text-primary">Carmichael's Automotive Services</h4>
            <p className="indent">
              We offer 24/7 emergency automotive services to Carmichael, CA and
              surrounding areas. Our technicians have been doing this for years
              and are experts in the auto locksmith business. We service all
              types of makes and models so you can be sure we can help you out.
            </p>
            <p className="indent mb-0">
              Whether you are locked out of your car, lost your car keys or
              looking to get transponder chip keys to name a few, Swift
              Locksmith technicians will take care of your auto locksmith needs
              quickly and efficiently. Call Swift Locksmith Carmichael anytime
              and we are always happy to help you!
            </p>
          </Container>
        </div>
      </section>
      <Container as="section" className="pb-0 pb-sm-5 mb-sm-5">
        <h2 className="text-center">Trusted & Dependable Locksmith</h2>
        <p className="indent">
          Swift Locksmith is a licensed company serving the Carmichael, CA area
          for over 10 years so you can depend on us. We offer a variety of
          locksmith services for residential, commercial and automotive
          locksmith needs. Whether you are looking to get your home re-keyed,
          your car lock repaired, or your locked out of your office, you can
          count on us.
        </p>
        <p className="indent">
          All of our Swift Locksmith technicians are trained to handle different
          locksmith situations and are friendly and professional so you can
          trust our team to get the job done. Our company is mobile and our vans
          are fully equipped with the proper tools so we come to you.
        </p>
        <p className="indent mb-5">
          We want to ensure people feel safe within the Carmichael community and
          it is our job to offer top notch locksmith services for every person
          to feel a sense of security at their home, business or in their
          vehicle. Whether you have an emergency or non-emergency locksmith
          need, we are always available to assist you, so please don't hesitate
          to call and we will be more than happy to chat with you!
        </p>
        <Row className="justify-content-center">
          <Col xs={12} md={4} className="mb-2 mb-md-0 col-sm-pad">
            <Img
              fluid={data.locksmithWorkingOnDoorLockImage.childImageSharp.fluid}
              alt="locksmith-working-on-door-lock"
              className="mh-500px mh-md-100"
            />
          </Col>
          <Col xs={12} md={4} className="mb-0 col-sm-pad">
            <Img
              fluid={data.locksmithCarInNeighborhoodImage.childImageSharp.fluid}
              alt="locksmith-car-in-neighborhood"
              className="mh-500px mh-md-100"
            />
          </Col>
          <Col xs={12} md={4} className="d-none d-md-block">
            <Img
              fluid={data.laserKeyMachineImage.childImageSharp.fluid}
              alt="laser-key-machine"
              className="mh-xs-500px mh-md-100"
            />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  )
}

export default CarmichaelPage
